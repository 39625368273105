import React, { useState, useEffect, useCallback } from "react";

const StatusWindow = ({ onConnect, isConnected, statusString }) => {
  const [fields, setFields] = useState({}); // Map of field values

  const updateFields = useCallback((statusString) => {
    if (!statusString || !statusString.includes("[LOG] ")) return; // Safe guard

    // Extract everything to the right of "[LOG] "
    const debugString = statusString.split("[LOG] ")[1];

    const regex = /(\w+):([\w\d/]+)|(\w+)=([\w\d/]+)/g;
    let match;
    const newFields = {};

    while ((match = regex.exec(debugString)) !== null) {
      let key, value;

      if (match[1] && match[2]) {
        key = match[1];
        value = match[2];
      } else if (match[3] && match[4]) {
        key = match[3];
        value = match[4];
      }

      if (key && value) {
        // Special handling for 'uptime'
        if (key === "uptime" && value.endsWith("s")) {
          const seconds = parseInt(value.slice(0, -1), 10);
          const hours = Math.floor(seconds / 3600);
          const minutes = Math.floor((seconds % 3600) / 60);
          const remainingSeconds = seconds % 60;
          value = `${hours}:${minutes}:${remainingSeconds}`;
        }

        newFields[key] = value;
      }
    }

    // Only update state if fields have changed
    setFields((prevFields) => {
      const hasChanged = Object.keys(newFields).some(
        (key) => prevFields[key] !== newFields[key]
      );

      return hasChanged ? newFields : prevFields;
    });
  }, []);

  useEffect(() => {
    updateFields(statusString);
  }, [statusString, updateFields]);

  const getPlaceholderText = () => {
    if (!isConnected) return "offline";
    if (Object.keys(fields).length === 0) return "waiting for DEBUG";
    return null;
  };

  const placeholderText = getPlaceholderText();

  return (
    <div
      style={{
        display: "flex",
        alignItems: "stretch",
        border: "1px solid #ccc",
        borderRadius: "8px",
        background: "#f9f9f9",
        marginBottom: "10px",
        height: "60px",
        padding: "10px",
      }}
    >
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(60px, 1fr))",
            gap: "10px",
          }}
        >
          {placeholderText ? (
            <div
              style={{
                gridColumn: "1 / -1", // Span the entire grid column
                color: "#999",
                fontStyle: "italic",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {placeholderText}
            </div>
          ) : (
            Object.keys(fields).map((key) => (
              <div
                key={key}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  border: "1px solid #ddd",
                  borderRadius: "4px",
                  padding: "10px",
                  background: "#fff",
                }}
              >
                <strong>{key}</strong>
                <span>{fields[key]}</span>
              </div>
            ))
          )}
        </div>
      </div>
      <div
        style={{
          marginLeft: "10px", // Space between the status area and the button
          display: "flex",
          //align to the top border
          alignItems: "flex-start",
        }}
      >
        <button
          onClick={onConnect}
          style={{
            width: "100px",
            height: "60px",
            background: isConnected ? "#d9534f" : "#5cb85c",
            color: "#fff",
            border: "none",
            borderRadius: "8px",
            cursor: "pointer",
          }}
        >
          {isConnected ? "Disconnect" : "Connect"}
        </button>
      </div>
    </div>
  );
};

export default StatusWindow;