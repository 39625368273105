import React, { useState, useEffect, useRef } from "react";
import { Terminal } from "xterm";
import { FitAddon } from "xterm-addon-fit";
import "xterm/css/xterm.css";
import { connectToSerialPort } from "./utils/serialManager";
import CommandPopup from "./components/CommandPopup";
import ManageCommands from "./components/ManageCommands";

const LinuxApp = () => {
  const [isPopupVisible, setIsPopupVisible] = useState(false); // Popup visibility
  const [isManageVisible, setIsManageVisible] = useState(false); // Manage commands visibility

  const [port, setPort] = useState(null);
  const prevPortRef = useRef(null);
  const [isConnected, setIsConnected] = useState(false);
  const terminalRef = useRef(null);
  const term = useRef(null);
  const fitAddon = useRef(null);
  const writer = useRef(null);
  const abortControllerRef = useRef(null); // AbortController for disconnect

  const builtInCommands = []
  const [commands, setCommands] = useState([]); // Commands array

  useEffect(() => {
    // Initialize the terminal
    term.current = new Terminal({
      cursorBlink: true,
      disableStdin: false,
      theme: {
        background: "#1e1e1e",
        foreground: "#ffffff",
      },
    });

    fitAddon.current = new FitAddon();
    term.current.loadAddon(fitAddon.current);

    if (terminalRef.current) {
      term.current.open(terminalRef.current);
      fitAddon.current.fit();
    }

    return () => {
      term.current.dispose();
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (fitAddon.current && terminalRef.current) {
        fitAddon.current.fit();
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleConnect = async () => {
    if (isConnected) {
      try {
        if (abortControllerRef.current) {
          abortControllerRef.current.abort();
        }

        await new Promise((resolve) => setTimeout(resolve, 1000));
        setPort(null);
        setIsConnected(false);
      } catch (error) {
        console.error("Error disconnecting:", error);
      }
    } else {
      const serialPort = await connectToSerialPort();
      if (serialPort) {
        setPort(serialPort);
        setIsConnected(true);
        console.log("Port connected.");
      }
    }
  };

  useEffect(() => {
    // Access the previous value of port
    const prevPort = prevPortRef.current;

    if (prevPort) {
      console.log("Previous port:", prevPort);
      prevPort.close();
    }

    // Update the previous value to the current port
    prevPortRef.current = port;

    if (!port) return;

    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    const signal = abortController.signal;

    const reader = port.readable.getReader();
    writer.current = port.writable.getWriter();
    const textEncoder = new TextEncoder();

    const processIncomingData = async () => {
      try {
        while (!signal.aborted) {
          const { value, done } = await reader.read();
          if (done || signal.aborted) break;
    
          if (value) {
              const chunk = new TextDecoder().decode(value);
              term.current.write(chunk);
          }
        }
      } catch (error) {
        if (!signal.aborted) console.error("Error reading data:", error);
      } finally {
        reader.releaseLock();
      }
    };

    processIncomingData();

    // Listen for user input
    term.current.onData((data) => {
      if (writer.current) {
        writer.current.write(textEncoder.encode(data));
      }
    });

    return () => {
      abortController.abort();
      reader.releaseLock();
      writer.current.releaseLock();
      writer.current = null;
    };
  }, [port]);

  const handlePopupCommandSelect = (command) => {
    if (writer.current) {
      writer.current.write(new TextEncoder().encode(command + "\n"));
    }
  }

  const handleCommandPopup = () => {
    setIsPopupVisible(true);
  }

  useEffect(() => {
    const savedCommands = JSON.parse(localStorage.getItem("linuxCommands")) || [];
    setCommands(savedCommands);
  }, []);

  const handleCommandUpdate = (commands) => {
    setCommands(commands);
  }

  const handleEditCommands = () => {
    setIsManageVisible(true);
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <div style={{ padding: "10px", background: "#333", textAlign: "center" }}>
        <ManageCommands
          commandStoreName="linuxCommands"
          isVisible={isManageVisible}
          onClose={() => setIsManageVisible(false)}
          onCommandUpdate={handleCommandUpdate}
        />

        {/* Popup */}
        <CommandPopup
          isVisible={isPopupVisible}
          onClose={() => setIsPopupVisible(false)}
          onCommandSelect={handlePopupCommandSelect}
          commands={[...builtInCommands, ...commands]}
        />

        <button
          onClick={handleConnect}
          style={{
            padding: "10px 20px",
            fontSize: "16px",
            color: "#fff",
            background: isConnected ? "#d9534f" : "#5cb85c",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
          }}
        >
          {isConnected ? "Disconnect" : "Connect"}
        </button>

        <button
          onClick={handleCommandPopup}
          disabled={!isConnected}
          style={{
            padding: "10px 20px",
            fontSize: "16px",
            color: "#fff",
            border: "none",
            background: isConnected ? "#007bff" : "#ccc",
            borderRadius: "4px",
            cursor: "pointer",
          }}
        >
          Commands
        </button>

        <button
          onClick={handleEditCommands}
          disabled={!isConnected}
          style={{
            padding: "10px 20px",
            fontSize: "16px",
            color: "#fff",
            border: "none",
            background: isConnected ? "#007bff" : "#ccc",
            borderRadius: "4px",
            cursor: "pointer",
          }}
        >
          Edit Cmds
        </button>
      </div>
      <div
        ref={terminalRef}
        style={{
          flex: 1,
          overflow: "hidden",
          backgroundColor: "#1e1e1e",
        }}
      />
    </div>
  );
};

export default LinuxApp;