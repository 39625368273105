import React, { useState } from "react";
import MCUApp from "./MCUApp";
import LinuxApp from "./LinuxApp";

const App = () => {
  const [activeTab, setActiveTab] = useState("MCU");

  return (
    <div style={{ overflow: "hidden", height: "100vh", display: "flex", flexDirection: "column" }}>
      {/* Tab Navigation */}
      <div style={{ display: "flex", borderBottom: "1px solid #ccc", backgroundColor: "#f4f4f4" }}>
        <button
          onClick={() => setActiveTab("MCU")}
          style={{
            flex: 1,
            padding: "10px",
            backgroundColor: activeTab === "MCU" ? "#007bff" : "#fff",
            color: activeTab === "MCU" ? "#fff" : "#000",
            border: "none",
            cursor: "pointer",
          }}
        >
          System Controller
        </button>
        <button
          onClick={() => setActiveTab("Linux")}
          style={{
            flex: 1,
            padding: "10px",
            backgroundColor: activeTab === "Linux" ? "#007bff" : "#fff",
            color: activeTab === "Linux" ? "#fff" : "#000",
            border: "none",
            cursor: "pointer",
          }}
        >
          Linux
        </button>
      </div>

      {/* Tab Content */}
      <div style={{ flex: 1, position: "relative" }}>
        {/* MCUApp */}
        <div
          style={{
            display: activeTab === "MCU" ? "block" : "none",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
        >
          <MCUApp />
        </div>

        {/* LinuxApp */}
        <div
          style={{
            display: activeTab === "Linux" ? "block" : "none",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
        >
          <LinuxApp />
        </div>
      </div>
    </div>
  );
};

export default App;