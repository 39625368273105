// src/utils/serialManager.js
export const connectToSerialPort = async () => {
  try {
    const port = await navigator.serial.requestPort();
    await port.open({
      baudRate: 115200, // Set baud rate
      dataBits: 8,      // 8 data bits
      stopBits: 1,      // 1 stop bit
      parity: "none",   // No parity
    });
    console.log("Serial port opened with 8N1 settings.");
    return port;
  } catch (error) {
    console.error("Failed to open serial port:", error);
    return null;
  }
};

export const sendCommand = async (port, command) => {
  if (!port || !port.writable) return;
  const writer = port.writable.getWriter();
  await writer.write(new TextEncoder().encode(command + "\n"));
  writer.releaseLock();
};
