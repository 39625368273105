import React, { useEffect, useRef } from "react";
import { Terminal } from "xterm";
import { FitAddon } from "xterm-addon-fit";
import "xterm/css/xterm.css";

const TerminalComponent = ({ log, clearLog }) => {
  const terminalRef = useRef(null);
  const term = useRef(null);
  const fitAddon = useRef(null);

  useEffect(() => {
    // Initialize Xterm.js
    term.current = new Terminal({
      cursorBlink: true,
      theme: {
        background: "#1e1e1e",
        foreground: "#ffffff",
      },
    });
    fitAddon.current = new FitAddon();
    term.current.loadAddon(fitAddon.current);

    if (terminalRef.current) {
      term.current.open(terminalRef.current);
      setTimeout(() => {
        fitAddon.current.fit(); // Delay the fit to ensure the container has a size
      }, 10);
    }

    return () => {
      term.current.dispose();
    };
  }, []);

  useEffect(() => {
    // Write new log messages to the terminal
    if (term.current && log) {

      //log the new logs we got
      console.log("Terminal New log messages:", log);

      log.forEach((line) => {
        term.current.writeln(line);
      });
    }
  }, [log]);

  const handleClear = () => {
    if (term.current) {
      term.current.clear(); // Clear the terminal
    }

    if( clearLog) {
      clearLog();
    }
  };

  return (
    <div style={{ height: "100%", width: "100%", display: "flex", flexDirection: "column" }}>
      {/* Button Section */}
      <div style={{ marginTop: "4px", marginBottom: "4px",  display: "flex", gap: "10px" }}>
        <button
          onClick={handleClear}
          style={{
            padding: "10px 20px",
            background: "#007bff",
            color: "white",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
          }}
        >
          Clear
        </button>
      </div>

      {/* Terminal Display */}
      <div
        ref={terminalRef}
        style={{
          flex: 1,
          border: "1px solid #444",
          borderRadius: "4px",
          marginBottom: "10px",
        }}
      ></div>

    </div>
  );
};

export default TerminalComponent;