import React, { useState, useRef, useEffect, useCallback } from "react";
import TerminalComponent from "./components/Terminal";
import CommandTerminal from "./components/CommandTerminal";
import { connectToSerialPort, sendCommand } from "./utils/serialManager";
import StatusWindow from "./components/StatusWindow";
import CommandPopup from "./components/CommandPopup";
import ManageCommands from "./components/ManageCommands";

const App = () => {
  const [isPopupVisible, setIsPopupVisible] = useState(false); // Popup visibility
  const [isManageVisible, setIsManageVisible] = useState(false); // Manage commands visibility

  const [port, setPort] = useState(null);
  const prevPortRef = useRef(null);
  const [log, setLog] = useState([]); // General logs
  const logRef = useRef([]); // Ref for log buffer

  const [commandLog, setCommandLog] = useState([]); // Right terminal logs
  const commandLogRef = useRef([]); // Ref for log buffer
  const responseBufferRef = useRef([]); // Ref for response buffer

  const [isConnected, setIsConnected] = useState(false); // Connection state
  const abortControllerRef = useRef(null); // Reference to AbortController
  const [statusString, setStatusString] = useState(""); // Status string

  const predefinedCommands = ["HELP:;", "PWR_ON:NORMAL:;", "PWR_OFF:SOFT:;", "PWR_ON:EDL:;" ]; // Predefined commands
  const [commands, setCommands] = useState([]); // Commands array

  const handleConnect = async () => {
    if (isConnected) {
      try {
        if (abortControllerRef.current) {
          abortControllerRef.current.abort();
        }

        await new Promise((resolve) => setTimeout(resolve, 1000));
        setPort(null);
        setIsConnected(false);
      } catch (error) {
        console.error("Error disconnecting:", error);
      }
    } else {
      const serialPort = await connectToSerialPort();
      if (serialPort) {
        setPort(serialPort);
        setIsConnected(true);
        console.log("Port connected.");
      }
    }
  };

  useEffect(() => {
    // Access the previous value of port
    const prevPort = prevPortRef.current;

    if (prevPort) {
      console.log("Previous port:", prevPort);
      prevPort.close();
    }

    // Update the previous value to the current port
    prevPortRef.current = port;

    if (!port) return;

    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    const signal = abortController.signal;

    const reader = port.readable.getReader();
    let buffer = "";

    const processIncomingData = async () => {
      try {
        while (!signal.aborted) {
          const { value, done } = await reader.read();
          if (done || signal.aborted) break;
    
          if (value) {
            const chunk = new TextDecoder().decode(value);
            buffer += chunk; // Append the received chunk to the buffer

            //print out the current buffer
            //console.log("Current buffer:", buffer);

            // Split the buffer into lines (if any!)
            const lines = buffer.split("\n");

            //print out the split lines
            //console.log("Split lines:", lines);

            let localLogs = [];
            let localResponse = [];

            for (let i = 0; i < lines.length - 1; i++) {
              const line = lines[i].trim(); // Remove extra whitespace

              //print line being processed
              console.log("Processing line:", line);

              // Detect lines with timestamps (e.g., "[123456] Log data")
              const isLogLine = /^\[\d+\]/.test(line);

              if (isLogLine) {
                // Log lines go to the general log
                localLogs.push(line);

                if (line.includes("[LOG]")) {
                  //console.log("[LOG] string:", line);
                  setStatusString(line);
                }

              } else {
                // Log lines go to the general log
                localLogs.push(line);

                // Non-log lines go to the response buffer
                localResponse.push(line);
              }
            }

            if (localLogs.length > 0) {
                for (const item of localLogs) {
                  console.log("Log add: ", item);
                }

                //overwrite the log with the new logs
                logRef.current = [...logRef.current, ...localLogs];
            }

            if (localResponse.length > 0) {
                //log these
                for (const item of localResponse) {
                  console.log("Response add: ", item);
                }

                //overwrite the response buffer with the new responses
                responseBufferRef.current = [...responseBufferRef.current, ...localResponse];
            }

            // Keep only the last incomplete line in the buffer
            buffer = lines[lines.length - 1];
            updateLogsFromBuffer(); // Update logs in batches
          }
        }
      } catch (error) {
        if (!signal.aborted) console.error("Error reading data:", error);
      } finally {
        reader.releaseLock();
      }
    };

    processIncomingData();

    return () => {
      abortController.abort();
      reader.releaseLock();
    };
  }, [port]);

  
  const updateLogsFromBuffer = useCallback(() => {
    // Prevent simultaneous updates
    let isUpdating = false;
  
    if (!isUpdating && (commandLogRef.current.length > 0 || logRef.current.length > 0 || responseBufferRef.current.length > 0)) {
      isUpdating = true;
  
      setTimeout(() => {
        console.log("Batching logs...");

        //print out the batched logs
        console.log("Batched logs:", logRef.current);
  
        // Update logs and clear buffers
        setLog([...logRef.current]);
        setCommandLog([
          ...commandLogRef.current,
          ...responseBufferRef.current,
        ]);
  
        logRef.current.length = 0; // Clear general log buffer
        responseBufferRef.current.length = 0; // Clear response buffer
        commandLogRef.current.length = 0; // Clear command log buffer
        isUpdating = false;
  
        console.log("Logs updated.");
      }, 500); // Batch updates every 50ms
    }
  }, []);

  const handleCommandBlocking = async (inputCommand) => {
    console.log("Sending command to MCU:", inputCommand);

    if (!port) {
      commandLogRef.current = [...commandLogRef.current, "Not connected to a serial port."];
      updateLogsFromBuffer();
      return;
    }

    commandLogRef.current = [...commandLogRef.current, `> ${inputCommand}`];
    updateLogsFromBuffer();
    await sendCommand(port, inputCommand);
  };

  const clearCommandLog = () => {
    setCommandLog([]); // Clear the command log state
  };

  const clearLog = () => {
    setLog([]); // Clear the log state
  };

  const handlePopupCommandSelect = async (command) => {
    console.log("Selected command:", command);
    // Send the selected command to the MCU
    if (command) {
      await sendCommand(port, command);
      commandLogRef.current = [...commandLogRef.current, `> ${command}`];
      updateLogsFromBuffer();
    }
  };

  const handleCommandPopup = () => {
    setIsPopupVisible(true);
  }

  const handleManagerCommandPopup = () => {
    setIsManageVisible(true);
  }

  useEffect(() => {
    const savedCommands = JSON.parse(localStorage.getItem("predefinedCommands")) || [];
    setCommands(savedCommands);
  }, []);

  const handleCommandUpdate = (updatedCommands) => {
    setCommands(updatedCommands);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh", // Full viewport height
        overflow: "hidden", // Prevent overall app from scrolling
      }}
    >
      <ManageCommands
        commandStoreName="predefinedCommands"
        isVisible={isManageVisible}
        onClose={() => setIsManageVisible(false)}
        onCommandUpdate={handleCommandUpdate}
      />

      {/* Popup */}
      <CommandPopup
        isVisible={isPopupVisible}
        onClose={() => setIsPopupVisible(false)}
        onCommandSelect={handlePopupCommandSelect}
        commands={[...predefinedCommands, ...commands]}
      />

      {/* Status Window */}
      <div
        style={{
          flexShrink: 0, // Prevent shrinking
          height: "80px", // Fixed height for the status window
          overflowY: "auto", // Enable internal scrolling for large content
          borderBottom: "1px solid #ccc", // Divider for clarity
          padding: "10px",
          background: "#f9f9f9", // Light background for better contrast
        }}
      >
        <StatusWindow
          onConnect={handleConnect}
          isConnected={isConnected}
          statusString={statusString || "[0000000] STATUS:"}
        />
      </div>
  
      {/* Main Content */}
      <div
        style={{
          display: "flex",
          flex: 1, // Use all remaining space
          overflow: "hidden", // Prevent terminals from overflowing the container
        }}
      >
        {/* Left Terminal */}
        <div
          style={{
            flex: 1,
            padding: "10px",
            borderRight: "1px solid #ccc",
            overflow: "auto", // Scrollable terminal content
          }}
        >
          <TerminalComponent log={log} clearLog={clearLog} />
        </div>
  
        {/* Right Terminal */}
        <div
          style={{
            flex: 1, 
            padding: "10px",
            overflow: "auto", // Scrollable terminal content
          }}
        >
          <CommandTerminal connected={isConnected} log={commandLog} onCommandPopup={handleCommandPopup} onManageCommandPopup={handleManagerCommandPopup} onSendCommand={handleCommandBlocking} onClearLogs={clearCommandLog} />
        </div>
      </div>
    </div>
  );
};

export default App;