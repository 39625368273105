import React, { useState, useEffect, useRef } from "react";

const ManageCommands = ({ commandStoreName, isVisible, onClose, onCommandUpdate }) => {
  const [commands, setCommands] = useState([]); // State for managing commands
  const [newCommand, setNewCommand] = useState(""); // State for new command input
  const isInitialized = useRef(false); // Tracks initialization status
  const isLoading = useRef(true); // Flag to prevent saving immediately after loading

  // Function to save commands to localStorage
  const saveCommandsToLocalStorage = (commands) => {
    try {
      const serializedCommands = JSON.stringify(commands);
      localStorage.setItem(commandStoreName, serializedCommands);
      console.log("Commands saved successfully!", serializedCommands);
    } catch (error) {
      console.error("Failed to save commands to localStorage:", error);
    }
  };

  // Function to load commands from localStorage
  const loadCommandsFromLocalStorage = () => {
    try {
      const serializedCommands = localStorage.getItem(commandStoreName);
      console.log("Commands loaded successfully!", serializedCommands);
      return serializedCommands ? JSON.parse(serializedCommands) : [];
    } catch (error) {
      console.error("Failed to load commands from localStorage:", error);
      return [];
    }
  };

  // Load commands only once
  useEffect(() => {
    if (!isInitialized.current) {
      const savedCommands = loadCommandsFromLocalStorage();
      setCommands(savedCommands);
      isInitialized.current = true; // Mark as initialized
      isLoading.current = false; // Mark as done loading
    }
  }, []);

  // Save commands whenever they change
  useEffect(() => {
    if (isLoading.current) return; // Skip saving during the initial load
    saveCommandsToLocalStorage(commands);
    if (onCommandUpdate) {
      onCommandUpdate(commands); // Notify parent of changes
    }
  }, [commands, onCommandUpdate]);

  // Handle adding a new command
  const handleAddCommand = () => {
    const trimmedCommand = newCommand.trim();
    if (trimmedCommand && !commands.includes(trimmedCommand)) {
      setCommands([...commands, trimmedCommand]);
      setNewCommand(""); // Clear input
    }
  };

  // Handle deleting a command
  const handleDeleteCommand = (commandToDelete) => {
    setCommands(commands.filter((cmd) => cmd !== commandToDelete));
  };

  // Do not render if the component is not visible
  if (!isVisible) return null;

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000,
      }}
    >
      <div
        style={{
          backgroundColor: "#fff",
          borderRadius: "8px",
          padding: "20px",
          width: "80%",
          maxWidth: "600px",
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
        }}
      >
        <h3 style={{ marginBottom: "20px", textAlign: "center" }}>Manage Commands</h3>
        <div
          style={{
            display: "flex",
            gap: "10px",
            marginBottom: "20px",
          }}
        >
          <input
            type="text"
            value={newCommand}
            onChange={(e) => setNewCommand(e.target.value)}
            placeholder="Enter new command"
            style={{
              flex: 1,
              padding: "10px",
              border: "1px solid #ccc",
              borderRadius: "4px",
            }}
          />
          <button
            onClick={handleAddCommand}
            style={{
              padding: "10px 20px",
              backgroundColor: "#007bff",
              color: "#fff",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            Add
          </button>
        </div>
        <div
          style={{
            maxHeight: "300px",
            overflowY: "auto",
            border: "1px solid #ddd",
            borderRadius: "4px",
            padding: "10px",
          }}
        >
          {commands.map((command, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "10px",
                padding: "10px",
                border: "1px solid #ccc",
                borderRadius: "4px",
                backgroundColor: "#f9f9f9",
              }}
            >
              <span>{command}</span>
              <button
                onClick={() => handleDeleteCommand(command)}
                style={{
                  padding: "5px 10px",
                  backgroundColor: "#d9534f",
                  color: "#fff",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
              >
                Delete
              </button>
            </div>
          ))}
        </div>
        <button
          onClick={onClose}
          style={{
            marginTop: "20px",
            padding: "12px 24px",
            backgroundColor: "#d9534f",
            color: "#fff",
            border: "none",
            borderRadius: "6px",
            cursor: "pointer",
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default ManageCommands;