// src/components/CommandTerminal.js
import React, { useEffect, useRef } from "react";
import { Terminal } from "xterm";
import { FitAddon } from "xterm-addon-fit";
import "xterm/css/xterm.css";

const CommandTerminal = ({ connected, log, onCommandPopup, onManageCommandPopup, onSendCommand, onClearLogs }) => {
  const terminalRef = useRef(null); // Terminal container reference
  const term = useRef(null); // Terminal instance
  const fitAddon = useRef(null); // FitAddon instance

  useEffect(() => {
    // Initialize terminal
    term.current = new Terminal({
      cursorBlink: false,
      disableStdin: true, // Read-only terminal
      theme: {
        background: "#1e1e1e",
        foreground: "#00ff00",
      },
    });

    fitAddon.current = new FitAddon();
    term.current.loadAddon(fitAddon.current);

    if (terminalRef.current) {
      term.current.open(terminalRef.current);
      setTimeout(() => {
        fitAddon.current.fit(); // Delay the fit to ensure the container has a size
      }, 10);
    }

    // Resize the terminal on window resize
    const handleResize = () => {
      console.log("Resizing command terminal");
      fitAddon.current.fit();
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      if (term.current) term.current.dispose();
    };
  }, []);

  useEffect(() => {
    // Write new log messages to the terminal
    if (term.current && log) {
      log.forEach((line) => {
        term.current.writeln(line);
      });
    }
  }, [log]);

  const handleInputChange = (e) => {
    if( connected ) {
        if (e.key === "Enter") {
          const command = e.target.value.trim();
          if (command) {
            onSendCommand(command); // Send the command to the parent
            e.target.value = ""; // Clear the input box
          }
        }
      }
  };

  const handleClear = () => {
    if (term.current) {
      term.current.reset(); // Reset the terminal visually
      if (onClearLogs) {
        onClearLogs(); // Notify parent to clear the log state
      }
    }
  };

  const handleCommandPopup = () => {
    if (connected && onCommandPopup ) {
      onCommandPopup()
    }
  }

  const handleManageCommandPopup = () => {
    if (onManageCommandPopup ) {
      onManageCommandPopup();
    }
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      {/* Button and Input Section */}
      <div style={{ marginTop: "4px", marginBottom: "4px",  display: "flex", gap: "10px" }}>
      <button
          onClick={handleCommandPopup}
          disabled={!connected}
          style={{
            padding: "10px 20px",
            background: connected ? "#007bff" : "#ccc",
            color: "white",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
          }}
        >
          Cmds
      </button>
      <button
          onClick={handleManageCommandPopup}
          style={{
            padding: "10px 20px",
            background: "#007bff",
            color: "white",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
          }}
        >
          Edit Cmds
      </button>
        <button
          onClick={handleClear}
          style={{
            padding: "10px 20px",
            background: "#007bff",
            color: "white",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
          }}
        >
          Clear
        </button>
        <input
          type="text"
          disabled={!connected}
          onKeyDown={handleInputChange}
          placeholder="Enter a command and press Enter"
          style={{
            flex: 1,
            padding: "10px",
            border: "1px solid #ccc",
            borderRadius: "4px",
            fontSize: "16px",
          }}
        />
      </div>

      {/* Terminal Display */}
      <div
        ref={terminalRef}
        style={{
          flex: 1,
          border: "1px solid #444",
          borderRadius: "4px",
        }}
      ></div>
    </div>
  );
};

export default CommandTerminal;

