import React from "react";

const CommandPopup = ({ isVisible, onClose, onCommandSelect, commands }) => {
  if (!isVisible) return null;

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000,
      }}
    >
      <div
        style={{
          backgroundColor: "#fff",
          borderRadius: "8px",
          padding: "20px",
          width: "80%",
          maxWidth: "600px", // Increased max width slightly
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
        }}
      >
        <h3 style={{ marginBottom: "20px", textAlign: "center" }}>
          Select a Command
        </h3>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(150px, 1fr))", // Adjusted button size
            gap: "15px", // Increased spacing for clarity
            maxWidth: "100%", // Ensures it stays within the container
          }}
        >
          {commands.map((command, index) => (
            <button
              key={index}
              onClick={() => {
                onCommandSelect(command);
                onClose();
              }}
              style={{
                padding: "15px", // Increased padding
                fontSize: "16px", // Increased font size
                backgroundColor: "#007bff",
                color: "#fff",
                border: "none",
                borderRadius: "6px", // Slightly rounded corners
                cursor: "pointer",
                textAlign: "center",
              }}
            >
              {command}
            </button>
          ))}
        </div>
        <button
          onClick={onClose}
          style={{
            marginTop: "20px",
            padding: "12px 24px",
            backgroundColor: "#d9534f",
            color: "#fff",
            border: "none",
            borderRadius: "6px",
            cursor: "pointer",
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default CommandPopup;